import { message } from "antd";
import axios from "axios";

const SECONDS = 30;
const MILLISECONDS = 1000;
const TIMEOUT = SECONDS * MILLISECONDS;
const TOKEN = localStorage.getItem("token");
const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const client = axios.create({
  baseURL: REACT_APP_BASE_API,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${TOKEN}`
  }
});

client.interceptors.request.use(function (config) {
  return config;
});
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data) {
      message.error(error?.response?.data?.message)
    } else if (error.response.status !== 200) {
      message.error(error.response.data.data);
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

const SearchEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post("/reports/searchEmployee", formData);
  return data;
}

const TodaysAttendenceList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post("/attendance", formData);
  return data;
}

const FirstInLastOutList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post('/filo', formData);
  return data;
}

const UnAuthorisedEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post('/reports/unAuthorized', formData);
  return data;
}

const dashboardAttendenceList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post('/attendance/v1', formData);
  return data;
}

const DwellEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post('/dwellReport', formData);
  return data;
}

const HoursWorkedEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.post('/hoursWorked', formData);
  return data;
}

const EmployeeDropdownList = async (id) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await client.get(`/employee/dropdown?departmentId=${id || ""}`);
  return data;
}

export {
  SearchEmployeeList,
  TodaysAttendenceList,
  FirstInLastOutList,
  UnAuthorisedEmployeeList,
  DwellEmployeeList,
  HoursWorkedEmployeeList,
  EmployeeDropdownList,
  dashboardAttendenceList
}