import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Pagination, Input, Button, DatePicker, Select, Form } from "antd";
import { useDwellEmployeeList, useEmployeeDropdownByDepartmentId } from "hooks/reports/reports";
import { exportToJson, removeSecondsFromColonSeperatedString } from "helpers/commonFunctions";
import { usePostDownloadCsv } from "hooks/csv/csv";
import { useDepartmentList } from "hooks/configuration/employee/employee";
import ReportHeader from "layouts/utils/reportHeader";
import Image from "helpers/Image";
import moment from "moment";
import "../style.css";

const { Option } = Select;

const Dwell = () => {

  const [form] = Form.useForm();

  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [initialValuesSet, setInitialValuesSet] = useState(false);

  const [initialValue, setInitialValue] = useState({
    fromDate: fromDate,
    toDate: toDate,
    searchKey: searchKey,
    pageNumber: pageNumber,
    pageSize: page,
    employeeId: employeeId,
    departmentId: departmentId
  });

  const { mutate: getEmployee, data: getEmployeeDetails, isLoading: loading, } = useDwellEmployeeList({
    fromDate: fromDate,
    toDate: toDate,
    searchKey: searchKey,
    pageNumber: pageNumber,
    pageSize: 10,
    employeeId: employeeId,
    departmentId: departmentId
  });



  const { mutate: getEmployeeByDepartmentId, data: employeeData } = useEmployeeDropdownByDepartmentId();
  const { data: departmentData } = useDepartmentList();
 
  const { mutate: donwloadCSV, isLoading: csvLoading, } = usePostDownloadCsv("dwellReport/csv", {
    fromDate: fromDate,
    toDate: toDate,
    searchKey: searchKey,
    pageNumber: pageNumber,
    pageSize: page,
    employeeId: employeeId,
    departmentId: departmentId
  });

  const onFetchEmployee = (values) => {
    const formData = JSON.stringify(values);
    getEmployee(formData, {
      onSuccess: (data) => {
        setEmployeeList(data?.data?.content);
      },
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setPage(initialValue.pageSize = pageSize)
  }

  const checkDisabledDate = (current) => current && current > moment().endOf("day");

  const onChange = (page) => {
    initialValue.pageNumber = page - 1;
    setInitialValue(initialValue);
    setPageNumber(page - 1);
  };

  const searchHandler = (e) => {
    initialValue.searchKey = e.target.value;
    let data = { ...initialValue };
    data.searchKey = e.target.value;
    data.pageNumber = e.target.value !== "" ? 0 : initialValue.pageNumber;
    setInitialValue(data);
    setSearchKey(e.target.value);
  };

  const getDate = (dates) => {
    if (dates) {
      const fromDate = moment.utc(dates).format();
      const toDate = moment.utc(dates).format();
      setFromDate(fromDate);
      setToDate(toDate);
      initialValue.fromDate = fromDate;
      initialValue.toDate = toDate;
    } else {
      setFromDate("");
      setToDate("");
      initialValue.fromDate = "";
      initialValue.toDate = "";
    }
    initialValue.pageNumber = 0;
    setPageNumber(0);
  };

  const setDepatmentField = (value) => {
    let selectedEmployee = employeeData?.data?.find((employee) => employee.employeeId === value);
    if (selectedEmployee) {
      form.setFieldsValue({ departmentId: selectedEmployee.departmentId });
      setDepartmentId(selectedEmployee.departmentId);
      initialValue.departmentId = selectedEmployee.departmentId;
    }
  }

  const handleEmployeeSelect = (value) => {
    setDepatmentField(value);
    initialValue.employeeId = value;
    initialValue.pageNumber = 0;
    setPageNumber(0);
    setEmployeeId(value);
  };

  const handleDepartmentSelect = (value) => {
    initialValue.departmentId = value;
    initialValue.employeeId = null;
    initialValue.pageNumber = 0;
    setPageNumber(0);
    if (value !== departmentId) {
      form.resetFields(["employeeId"]);
    }
    setDepartmentId(value);
    setEmployeeId(null)
  };

  const checkDisabledCSV = () => {
    return !fromDate ||
      fromDate === undefined ||
      fromDate.length === 0 ||
      !toDate ||
      toDate === undefined ||
      toDate.length === 0
  }

  const refetchDownload = (values) => {
    const formData = JSON.stringify(values);
    donwloadCSV(formData, {
      onSuccess: (data) => {
        if (data) {
          exportToJson(data, "Dwell_Report");
        }
      },
    });
  };

  const clearDepartment = () => {
    setDepartmentId(null);
    initialValue.departmentId = null;
  }

  const clearEmployee = () => {
    setEmployeeId(null);
    initialValue.employeeId = null;
  }

  useEffect(() => {
    if (!initialValuesSet) {
      let tdyDate = moment.utc().format();
      setFromDate(tdyDate);
      setToDate(tdyDate);
      initialValue.fromDate = tdyDate;
      initialValue.toDate = tdyDate;
      setInitialValuesSet(true);
    } else {
      // Make the API request here with the updated fromDate and toDate values
      onFetchEmployee(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, searchKey, fromDate, toDate, employeeId, departmentId, page]);

  useEffect(() => {
    getEmployeeByDepartmentId(departmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId])

  // useEffect(() => {
  //   form.setFieldsValue({
  //     "departmentId": departmentData?.data[0]?.departmentId,
  //     "employeeId": employeeData?.data[0]?.employeeId,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [departmentData])

  return (
    <Fragment>
      <ReportHeader />
      <Row className="common-head search-dwell" gutter={[12, 0]}>
        <Col md={4}>
          <div className="title">
            <h1>Dwell</h1>
            <p>Manage all dwell</p>
          </div>
        </Col>
        <Col md={5} className="txt-right">
          <DatePicker onChange={getDate} defaultValue={moment()} disabledDate={checkDisabledDate} format={"DD/MM/YYYY"} placeholder={"Select Date"} />
        </Col>
        <Col md={8}>
          <Form form={form}>
            <Row gutter={[12, 0]}>
              <Col md={12}>
                <Form.Item name="departmentId">
                  <Select allowClear onClear={clearDepartment} placeholder="Select Department" onSelect={handleDepartmentSelect}>
                    {departmentData?.data?.map((common, a) => (
                      <Option key={a} value={common?.departmentId}>
                        {common?.departmentName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item name="employeeId">
                  <Select allowClear onClear={clearEmployee} placeholder="Select Employee" showSearch onSelect={handleEmployeeSelect}
                    filterOption={(input, option) => option?.children?.includes(input)}
                    filterSort={(optionA, optionB) => optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())}>
                    {employeeData?.data?.map((common, a) => (
                      <Option key={a} value={common?.employeeId}>{common?.employeeName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md={4}>
          <Input className="report-search" onChange={searchHandler} placeholder="Search" prefix={<Image className="search-img" name={"search.svg"} />} allowClear />
        </Col>
        <Col md={3}>
          <Button onClick={() => refetchDownload()} disabled={checkDisabledCSV()} block loading={csvLoading} type="primary" className="csv">Download CSV</Button>
        </Col>
      </Row>
      <div className="common-table">
        <table>
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Site</th>
              <th>Zone</th>
              <th>Arrived</th>
              <th>Left</th>
              <th>Total hour spent in Zone</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <div className="loading"><h4>Loading.....</h4></div>
            ) : employeeList == null || employeeList === undefined || employeeList?.length === 0 ? (
              <div className="loading"><h4>No data found</h4></div>
            ) : employeeList.map((item) => (
              <tr>
                <td>{item?.employeeName}</td>
                <td>{item?.siteName}</td>
                <td>{item?.zoneName}</td>
                <td>{removeSecondsFromColonSeperatedString(item?.arrived) || "-"}</td>
                <td>{removeSecondsFromColonSeperatedString(item?.left) || "-"}</td>
                <td>{removeSecondsFromColonSeperatedString(item?.timeOfPresence)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Row className="table-pagination">
        <Col md={12}>
          <h5>Showing {pageNumber === 0 ? 1 : (getEmployeeDetails?.data?.pageable?.offset + 1 || 0)} to {page === 0 ? (getEmployeeDetails?.data?.pageable?.offset + employeeList?.length || 0) : (getEmployeeDetails?.data?.pageable?.offset + getEmployeeDetails?.data?.numberOfElements || 0)} of {getEmployeeDetails?.data?.totalElements} entries</h5>
        </Col>
        <Col md={12} className="txt-right">
          {getEmployeeDetails?.data?.content?.length === 0 ? null : <Pagination showSizeChanger={getEmployeeDetails?.data?.totalElements > 100 ? true : false} onChange={onChange} current={pageNumber + 1} onShowSizeChange={onShowSizeChange} total={getEmployeeDetails?.data?.totalElements} />}
        </Col>
      </Row>
    </Fragment >
  );
};

export default Dwell;
