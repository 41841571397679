import { useMutation, useQuery } from "react-query"
import * as reports from "services/reports/reports"

export const useSearchEmployeeList = () => {
    return useMutation((formdata) => reports.SearchEmployeeList(formdata));
}

export const useAttendenceList = () => {
    return useMutation((formdata) => reports.TodaysAttendenceList(formdata));
}

export const useFirstInLastOutList = () => {
    return useMutation((formdata) => reports.FirstInLastOutList(formdata));
}

export const useUnAuthorizedEmployeeList = () => {
    return useMutation((formdata) => reports.UnAuthorisedEmployeeList(formdata));
}

export const useDashboardAttendenceList = () => {
    return useMutation((formdata) => reports.dashboardAttendenceList(formdata));
}

export const useDwellEmployeeList = () => {
    return useMutation((formdata) => reports.DwellEmployeeList(formdata));
}

export const useHoursWorkedEmployeeList = () => {
    return useMutation((formdata) => reports.HoursWorkedEmployeeList(formdata));
}

export const useEmployeeDropdownByDepartmentId = () => {
    return useMutation((id) => reports.EmployeeDropdownList(id))
}

export const useEmployeeList = () => {
    return useQuery("EmployeeDropdown", () => reports.EmployeeDropdownList(), {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    });
  };